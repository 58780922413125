<template>
    <v-card flat tile>
        <v-tabs v-if="!showEdit" v-model="tab" background-color="transparent">
            <v-tab key="list">
                {{ $t('settings.lang_kitchenmonitor') }}
            </v-tab>

            <v-tab key="add">
                {{ $t('settings.lang_kitchenmonitorCreate') }}
            </v-tab>

            <v-tab key="settings">
                {{ $t('settings.lang_settings') }}
            </v-tab>

            <v-tab @click="openKitchenmon()">
                {{ $t('settings.lang_kitchenmonitorOpen') }}
            </v-tab>

            <v-tabs-items touchless v-model="tab">
                <v-tab-item key="list">
                    <Datatable ref="kitchenMonitorDatatable"
                        :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.KITCHENMONITORS"
                        :datatable-headers="datatableHeaders" :excel-columns="excelColumns"
                        excel-file-name="Küchenmonitor" @editEntry="editKitchenmonitor" @deleteEntry="deleteEntry"
                        @deleteEntries="deleteEntries" show-delete-buttons show-edit-buttons
                        :permissionDelete="this.$store.getters['permissions/checkPermission']('deposit')"
                        :permissionEdit="this.$store.getters['permissions/checkPermission']('deposit')" />
                </v-tab-item>

                <v-tab-item key="add">
                    <v-container fluid>
                        <v-row wrap>
                            <v-col md="6" col="12">
                                <v-text-field dense outlined type="text" v-model="name"
                                    :label="$t('generic.lang_name')"></v-text-field>
                            </v-col>

                            <v-col offset-md1 md="5" col="12">
                                <v-text-field dense outlined type="text" v-model="location"
                                    :label="$t('generic.lang_location')"></v-text-field>
                            </v-col>

                            <v-col md="12">
                                <v-textarea outlined v-model="description" hide-details
                                    :label="$t('generic.lang_description')"></v-textarea>
                            </v-col>

                            <v-col md="12">
                                <v-switch color="primary" v-model="printWithoutCut" hide-details
                                    :label="$t('settings.lang_print_all_items_without_cutting')"></v-switch>
                            </v-col>

                            <v-col md="12">
                                <v-switch color="primary" v-model="printBookedExtras" hide-details
                                    :label="$t('settings.lang_kitchenMonPrintGarnish')"></v-switch>
                            </v-col>

                            <v-col md="6" col="12">
                                <v-select :items="filteredPrinters" :label="$t('settings.lang_printer')"
                                    item-text="name" item-value="id" outlined clearable v-model="printerID"></v-select>
                            </v-col>

                            <v-col offset-md1 md="5" col="12">
                                <v-text-field v-model="mealDangerTime" @keydown="orderWarningKeyDown" clearable
                                    :label="$t('settings.lang_orderWarning')" outlined></v-text-field>
                            </v-col>

                            <v-col md="12">
                                <v-row>
                                    <v-col cols="12">
                                        <v-select :label="this.$t('generic.lang_playSoundOnNewOrder')" outlined
                                            hide-details item-value="id" item-text="name" :disabled="loading"
                                            :loading="loading" v-model="notificationSound" :items="sounds"
                                            return-object>
                                            <template v-slot:append-outer>
                                                <v-btn class="ma-0 mt-n3" fab small color="primary" :disabled="loading">
                                                    <v-icon color="white" @click="playSound">
                                                        mdi-play
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        {{ $t('settings.lang_selectDefaultKitchenmonitorTmpl') }}
                                        <v-btn-toggle v-model="template" active-class="success">
                                            <v-btn :value="0" class="ma-0">
                                                {{ $t('settings.lang_restaurantLayout') }}
                                            </v-btn>
                                            <v-btn :value="1" class="ma-0">
                                                {{ $t('settings.lang_fastFoodLayout') }}
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col md="12" class="text-right">
                                <v-btn text color="error">{{ $t('generic.lang_prev') }}</v-btn>
                                <v-btn color="success" :loading="loading2" :disabled="name < 4 || loading2"
                                    @click="createKitchenmonitor">{{ $t('generic.lang_add') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="settings">
                    <KitchenMonitorSettings />
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>

        <v-container fluid v-else>
            <v-row wrap>
                <v-col md="6" col="12">
                    <v-text-field dense outlined type="text" v-model="name"
                        :label="$t('generic.lang_name')"></v-text-field>
                </v-col>

                <v-col offset-md1 md="5" col="12">
                    <v-text-field dense outlined type="text" v-model="location"
                        :label="$t('generic.lang_location')"></v-text-field>
                </v-col>

                <v-col md="12">
                    <v-switch color="primary" v-model="active" :label="$t('settings.lang_settings_activ')"></v-switch>
                </v-col>

                <v-col md="12">
                    <v-textarea hide-details outlined v-model="description"
                        :label="$t('generic.lang_description')"></v-textarea>
                </v-col>

                <v-col md="12">
                    <v-switch color="primary" v-model="printWithoutCut"
                        :label="$t('settings.lang_print_all_items_without_cutting')"></v-switch>
                </v-col>

                <v-col md="12">
                    <v-switch color="primary" v-model="printBookedExtras"
                        :label="$t('settings.lang_kitchenMonPrintGarnish')"></v-switch>
                </v-col>

                <v-col md="6" col="12">
                    <v-select :items="filteredPrinters" :label="$t('settings.lang_printer')" item-text="name"
                        item-value="id" outlined clearable v-model="printerID"></v-select>
                </v-col>

                <v-col offset-md1 md="5" col="12">
                    <v-text-field v-model="mealDangerTime" @keydown="orderWarningKeyDown" clearable
                        :label="$t('settings.lang_orderWarning')" outlined></v-text-field>
                </v-col>

                <v-col md="12" class="mb-10">
                    <v-row>
                        <v-col cols="12">
                            <v-select :label="this.$t('generic.lang_playSoundOnNewOrder')" outlined hide-details
                                item-value="id" item-text="name" :disabled="loading" :loading="loading"
                                v-model="notificationSound" :items="sounds" return-object>
                                <template v-slot:append-outer>
                                    <v-btn class="ma-0 mt-n3" fab small color="primary" :disabled="loading">
                                        <v-icon color="white" @click="playSound">
                                            mdi-play
                                        </v-icon>
                                    </v-btn>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            {{ $t('settings.lang_selectDefaultKitchenmonitorTmpl') }}
                            <v-btn-toggle v-model="template" active-class="success">
                                <v-btn :value="0" class="ma-0">
                                    {{ $t('settings.lang_restaurantLayout') }}
                                </v-btn>
                                <v-btn :value="1" class="ma-0">
                                    {{ $t('settings.lang_fastFoodLayout') }}
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col md="12">
                    <v-btn text color="error" @click="showEdit = false">{{ $t('generic.lang_prev') }}</v-btn>
                    <v-btn color="success" :loading="loading3" :disabled="name < 4 || loading3"
                        @click="updateKitchenmonitor">
                        {{ $t('generic.lang_save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import moment from "moment";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "../../../config";
import mixin from "../../../mixins/KeyboardMixIns";
import Datatable from "@/components/datatable/Datatable";
import {mapState} from "vuex";
import KitchenMonitorSettings from "./KitchenMonitorSettings.vue";

export default {
    name: "Index",

    components: {
        Datatable,
        KitchenMonitorSettings
    },

    mixins: [mixin],

    data() {
        return {
            ENDPOINTS,
            tab: 0,
            loading: false,
            loading2: false,
            loading3: false,

            id: 0,
            name: "",
            location: "",
            description: "",
            active: false,
            printerID: 0,
            printWithoutCut: false,
            printBookedExtras: false,
            template: 0,
            mealDangerTime: "0:00",


            showEdit: false,
            // --- Datatable ---
            selectedRows: [],
            datatableHeaders: [
                {
                    text: 'ID',
                    align: 'left',
                    value: 'id',
                    width: 80,
                    hide: true
                },
                {text: this.$t('settings.lang_monitor'), value: 'name'},
                {text: this.$t('generic.lang_location'), value: 'location'},
                {text: this.$t('generic.lang_description'), value: 'description'},
                {text: '', value: 'status', align: 'right'},
                {text: this.$t('settings.lang_printer'), value: 'printerID', hide: true},
                {text: this.$t('settings.lang_print_all_items_without_cutting'), value: 'printWithoutCut', hide: true},
                {text: this.$t('settings.lang_kitchenMonPrintGarnish'), value: 'printBookedExtras', hide: true},
                {text: '', value: 'template', hide: true},
                {text: '', value: 'mealDangerTime', hide: true},
                {text: '', value: 'notificationSound', hide: true},
                {text: '', value: ''},
            ],
            excelColumns: [

                {
                    label: "ID",
                    field: 'id',
                },
                {
                    label: this.$t('settings.lang_monitor'),
                    field: "monitorName",
                },
                {
                    label: this.$t('generic.lang_location'),
                    field: "location",
                },
                {
                    label: this.$t('generic.lang_description'),
                    field: "description",
                },
            ],
            kitchenmonitors: [],
            notificationSound: 0,
            sounds: [
                {
                    id: 0,
                    name: this.$t('generic.lang_none'),
                    src: '',
                },
                {
                    id: 1,
                    name: "mixkit-bell-notification-933",
                    src: require("@/assets/sounds/mixkit-bell-notification-933.wav"),
                },
                {
                    id: 2,
                    name: "mixkit-clear-announce-tones-2861",
                    src: require("@/assets/sounds/mixkit-clear-announce-tones-2861.wav"),
                },
                {
                    id: 3,
                    name: "mixkit-correct-answer-reward-952",
                    src: require("@/assets/sounds/mixkit-correct-answer-reward-952.wav"),
                },
                {
                    id: 4,
                    name: "mixkit-doorbell-single-press-333",
                    src: require("@/assets/sounds/mixkit-doorbell-single-press-333.wav"),
                },
                {
                    id: 5,
                    name: "mixkit-happy-bells-notification-937",
                    src: require("@/assets/sounds/mixkit-happy-bells-notification-937.wav"),
                },
                {
                    id: 6,
                    name: "mixkit-magic-notification-ring-2344",
                    src: require("@/assets/sounds/mixkit-magic-notification-ring-2344.wav"),
                },
                {
                    id: 7,
                    name: "mixkit-musical-alert-notification-2309",
                    src: require("@/assets/sounds/mixkit-musical-alert-notification-2309.wav"),
                },
                {
                    id: 8,
                    name: "mixkit-sci-fi-reject-notification-896",
                    src: require("@/assets/sounds/mixkit-sci-fi-reject-notification-896.wav"),
                },
                {
                    id: 9,
                    name: "notification",
                    src: require("@/assets/sounds/notification.mp3"),
                },
                {
                    id: 10,
                    name: "notification2",
                    src: require("@/assets/sounds/notification2.mp3"),
                },
                {
                    id: 11,
                    name: "notification3",
                    src: require("@/assets/sounds/notification3.mp3"),
                },
                {
                    id: 12,
                    name: "notification4",
                    src: require("@/assets/sounds/notification4.mp3"),
                },
                {
                    id: 13,
                    name: "notification5",
                    src: require("@/assets/sounds/notification5.mp3"),
                },
            ],
        }
    },

    computed: {
        ...mapState(['printer']),
        filteredPrinters() {
            return this.printer.printers.filter((printer) => printer.type === 1 || printer.type === 2);
        }
    },

    watch: {
        showEdit(val) {
            if (!val) {
                this.name = "";
                this.id = "";
                this.description = "";
                this.location = "";
                this.printWithoutCut = false;
                this.printBookedExtras = false;
                this.template = 0;
                this.mealDangerTime = "0:00";
            }
        }
    },

    methods: {
        playSound() {
            if (this.notificationSound) {
                let audio = new Audio(this.notificationSound.src);
                audio.play();
            } else {
                Events.$emit("showSnackbar", {
                    color: "warning",
                    message: this.$t('generic.lang_pleaseSelectSoundToPlay')
                });
            }
        },
        orderWarningKeyDown(event) {
            if(!isNaN(event.key)) {
                let val = this.mealDangerTime ? this.mealDangerTime.replaceAll(":", "") + event.key : event.key;

                if (Number(val).toString().length <= 2) {
                    this.mealDangerTime = moment(Number(val), "ss").format("m:ss");
                } else {
                    let v = Number(val).toString();
                    this.mealDangerTime = this.insertAtIndex(v, ':', v.length - 2);
                } 
            } else if (event.key === 'Backspace' && this.mealDangerTime) {
                this.mealDangerTime = this.mealDangerTime.slice(0, -1);
            }

            event.preventDefault();
        },
        insertAtIndex(str, substring, index) {
            return str.slice(0, index) + substring + str.slice(index);
        },
        openKitchenmon() {
            let kitchenmonitorURL = process.env.VUE_APP_KITCHENMONITOR_URL

            if (process.env.VUE_APP_HYBRID == "true") {
                if (window.location.hostname != 'localhost' && window.location.hostname != 'devkasse.3pos.de' && window.location.hostname != 'kasse.3pos.de') {
                    kitchenmonitorURL = location.protocol + "//" + window.location.hostname + ":8037";
                }
            }

            window.open(kitchenmonitorURL, '_blank');
        },
        deleteEntry(entry) {
            let id = [];
            id.push(entry.id);
            this.deleteData(id);
        },
        deleteEntries(entries) {
            this.deleteData(entries);
        },
        deleteData(ids) {
            let self = this;
            this.$swal({
                title: this.$t("settings.lang_deleteKitchenmonitorHead"),
                text: this.$t("settings.lang_deleteKitchenmonitorBody"),
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_delete'),
                preConfirm: () => {
                    this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.DELETE, {
                        ids: ids
                    }).then((res) => {
                        if (res.data.success) {
                            Events.$emit("showSnackbar", {
                                message: this.$t("generic.lang_ActionSuccessful"),
                                color: "success",
                            });
                            self.$refs.kitchenMonitorDatatable.getDataFromApi();
                            self.$refs.kitchenMonitorDatatable.resetSelectedRows();
                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t("generic.lang_errorOccurred"),
                                color: "error",
                            });
                        }
                    });
                },
                allowOutsideClick: () => !this.$swal.isLoading,
            });
        },
        createKitchenmonitor() {
            this.loading2 = true;

            let mealDangerTime = '0';

            if (this.mealDangerTime) {
                mealDangerTime = moment.duration(`00:${this.mealDangerTime}`).asSeconds();
            }

            this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.CREATE, {
                name: this.name,
                location: this.location,
                description: this.description,
                printerID: this.printerID,
                printWithoutCut: this.printWithoutCut,
                printBookedExtras: this.printBookedExtras,
                template: this.template,
                mealDangerTime: mealDangerTime,
                notificationSound: this.notificationSound?this.notificationSound.id:0,
            }).then((res) => {
                this.loading2 = false;

                if (res.data.success) {
                    this.tab = 0;
                    this.name = "";
                    this.location = "";
                    this.description = "";
                    this.printerID = 0;
                    this.printWithoutCut = false;
                    this.printBookedExtras = false;
                    this.template = 0;
                    this.mealDangerTime = "0:00";

                    this.$refs.kitchenMonitorDatatable.getDataFromApi();
                    this.$refs.kitchenMonitorDatatable.resetSelectedRows();
                }
            }).catch(() => {
                this.loading2 = false;
            });
        },
        editKitchenmonitor(monitor) {
            this.name = monitor.name;
            this.location = monitor.name;
            this.description = monitor.description;
            this.printerID = parseInt(monitor.printerID);
            this.printWithoutCut = parseInt(monitor.printWithoutCut) == 1 ? true : false;
            this.printBookedExtras = parseInt(monitor.printBookedExtras) == 1 ? true : false;
            this.template = parseInt(monitor.template);
            this.mealDangerTime = moment.utc(monitor.mealDangerTime * 1000).format('mm:ss');
            let sound = this.sounds.find(s => s.id === Number(monitor.notificationSound))
            this.notificationSound = sound ? sound : 0;
            this.active = (parseInt(monitor.status) ? 1 : 0);
            this.id = monitor.id;

            this.showEdit = true;
        },
        updateKitchenmonitor() {
            this.loading3 = true;

            let mealDangerTime = '0';

            if (this.mealDangerTime) {
                mealDangerTime = moment.duration(`00:${this.mealDangerTime}`).asSeconds();
            }

            this.axios.post(ENDPOINTS.SETTINGS.KITCHENMONITORS.UPDATE, {
                id: this.id,
                name: this.name,
                location: this.location,
                description: this.description,
                status: (this.active ? 1 : 0),
                printerID: this.printerID,
                printWithoutCut: this.printWithoutCut,
                printBookedExtras: this.printBookedExtras,
                template: this.template,
                notificationSound: this.notificationSound?this.notificationSound.id:0,
                mealDangerTime: mealDangerTime
            }).then((res) => {
                this.loading3 = false;

                if (res.data.success) {
                    this.tab = 0;
                    this.name = "";
                    this.location = "";
                    this.description = "";
                    this.printerID = 0;
                    this.printWithoutCut = false;
                    this.printBookedExtras = false;
                    this.template = 0;
                    this.mealDangerTime = "0:00";
                    this.id = 0;
                    this.showEdit = false;

                    this.$refs.kitchenMonitorDatatable.getDataFromApi();
                    this.$refs.kitchenMonitorDatatable.resetSelectedRows();
                }
            }).catch(() => {
                this.loading3 = false;
            });
        },
    }
}
</script>